<template>
    <div class="help">
        <Header></Header>
        <div class="banner-help">
            <div class="banner-help-font">帮助中心</div>
        </div>
        <div class="help-center">
            <div class="qiehuan">
                <el-menu
                    default-active="2"
                    class="el-menu-vertical-demo"
                    :router="true"
                    :default-openeds="['/help/cate/1']"
                >
                    <el-menu-item index="/help">
                        <i class="el-icon-s-home"></i>
                        <template slot="title">帮助中心</template>
                    </el-menu-item>
                    <el-submenu
                        v-for="(cate, index) in categorys"
                        :index="'/help/cate/' + cate.id"
                        :key="index"
                    >
                        <template slot="title">{{ cate.name }}</template>
                        <el-menu-item
                            v-if="cate.children.length > 0"
                            :index="'/help/cate/' + cat.id"
                            v-for="(cat, key) in cate.children"
                            :key="key"
                        >
                            {{ cat.name }}
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </div>
            <!-- 右侧的帮助 -->
            <div class="help-center-right">
                <div class="help-center-right-top">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(ctwo,ktwo) in breadcrumbs" :key="ktwo">
                      {{ ctwo.name }}
                    </el-breadcrumb-item>
                  </el-breadcrumb>
                </div>
                <!-- 下面的认证 -->
                <div class="help-center-right-attestation">
                    <div class="help-center-right-attestation-center">
                        <div class="help-center-right-attestation-top">
                          <p style="text-align: center">{{ contentInfo.title }}</p>
                        </div>
                        <div
                            class="help-center-right-attestation-main"
                            v-html="contentInfo.text"
                        ></div>

                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { getContentCategorys, getContentInfo } from "@/api/cms";
export default {
    name: "helpinfo",
    data() {
        return {
            categorys: [],
            contents: [],
            catId: 0,
            contentInfo: {},
            breadcrumbs: [],
        };
    },
    created() {
        this.loadContentCategorys();
        this.contentId = this.$route.params.id;
        console.log( this.contentId );
        if (this.contentId != '' ) {
            this.loadContentInfo({ uuid: this.contentId });
        }
    },
    methods: {
        async loadContentCategorys() {
            const res = await getContentCategorys();
            if (res.statusCode == 200) {
                this.categorys = res.data;
            }
        },
        async loadContentInfo(data) {
            const res = await getContentInfo(data);
            if (res.statusCode == 200) {
                this.contentInfo = res.data;
                this.$nextTick(() => {
                    this.buildBreadcrumbs(this.contentInfo.catid);
                });
            }
        },
        buildBreadcrumbs(newID) {
            let data = [];
            this.categorys.forEach((Element) => {
                if (Element.children.length > 0) {
                    Element.children.forEach((e) => {
                        if (e.id == newID) {
                            data.push({ name: Element.name });
                            data.push(e);
                        }
                    });
                }
            });
            this.breadcrumbs = data;
        },
    },
};
</script>
<style>


.help-center-right-attestation-main p {
  text-indent: 2rem;
  pading:5px 0 5px;
  font-font:PingFang SC, Lantinghei SC, Microsoft YaHei, HanHei SC, Helvetica Neue, Open Sans, Arial, Hiragino Sans GB, \\5FAE\8F6F\96C5\9ED1, STHeiti, WenQuanYi Micro Hei, SimSun, sans-serif;
}

</style>
<style lang="less" scoped>
/deep/.el-submenu__title {
    font-size: 14px;
    font-weight: bold;
}
/deep/.el-submenu .el-menu-item {
    height: 45px;
}
.qiehuan {
    width: 240px;
}
.help {
    background-color: #f8f8f8;
}
.banner-help {
  width: 100%;
  background-size: cover;
  background-image: url(~@/assets/banner/底图.jpg);
  position: relative;
  text-align: center;
  .banner-help-font {
    line-height: 36px;
    color: #fff;
    font-size: 36px;
    padding: 40px 0;
  }
}
.help-center {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    margin-top: 20px;
    .help-center-right {
        margin-left: 20px;
        .help-center-right-top {
            width: 100%;
            line-height: 36px;
            font-size: 18px;
            color: #474747;
            padding:10px 0 ;
        }
        .help-center-right-attestation {
            width: 940px;
            background: #ffffff;
            display: flex;
            .help-center-right-attestation-center {
                width: 860px;
                margin: 0 auto;
                .help-center-right-attestation-top {
                    margin-top: 40px;
                    margin-bottom: 22px;
                    font-size: 18px;
                }
                .help-center-right-attestation-main {
                    width: 100%;
                    min-height: 520px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    color: #333;
                    text-align: justify;
                    line-height: 30px;
                    letter-spacing: 1px;

                    .help-center-right-attestation-main2 {
                        margin-top: 50px;
                    }
                }
            }
        }
    }
}
</style>
