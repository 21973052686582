import request from './ajax'

export const getContentCategorys = (data) =>
  request({
    method: 'get',
    url: '/cms/category',
    params: data,
  })

export const getContents = (data) =>
  request({
    method: 'get',
    url: '/cms/category/content/list',
    params: data,
  })

export const getContentInfo = (data) =>
  request({
    method: 'get',
    url: '/cms/content',
    params: data,
  })


